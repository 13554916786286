import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import SalonCaroussel from "./SalonCaroussel";

export default function Salon() {
  const matches = useMediaQuery("(min-width:1200px)");

  return (
    <Box sx={{ backgroundColor: "#fff9f1" }}>
      {matches && (
        <Grid container>
          <Grid item xs={6}>
            {SalonInfo()}
          </Grid>
          <Grid item xs={6}>
            <SalonCaroussel />
          </Grid>
        </Grid>
      )}
      {!matches && (
        <Container component={'div'}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {SalonInfo()}
            <SalonCaroussel />
          </Box>
        </Container>
      )}
    </Box>
  );
}

function SalonInfo() {
    const minWidth = useMediaQuery("(min-width:1000px)");

    return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        justifyContent: "center"
      }&& (minWidth ? {p: 10} : {p: 0})}
    >
      <Typography color="#b88f51" sx={{ fontFamily: "Ms Madi" }} variant="h2">
        Het Salon
      </Typography>
      <Typography
        color="#333333"
        sx={{ fontFamily: "Open Sans", mt: 5, mb: 5, fontSize: "20px" }}
      >
        Mijn salon is ingericht met kwaliteitsvol materiaal om jouw viervoeter
        met de grootste zorg te kunnen omringen en de stress tot een minimum te
        beperken.
      </Typography>
      <Divider color="#b88f51" sx={{}} flexItem={true} variant="middle" />
      <Typography
        color="#333333"
        sx={{ fontFamily: "Open Sans", mt: 5, fontSize: "20px" }}
      >
          Tevens werk ik met professionele verzorgingsproducten, dewelke u op aanvraag bij mij kan bestellen.
      </Typography>
    </Box>
  );
}
