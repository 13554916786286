import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";

export default function ContactInfo() {
  const matches = useMediaQuery("(min-width:1000px)");

  return (
    <Box sx={!matches ? {width: '100%', alignItems:'center'} : {display: "flex", flexDirection: 'column', height: '100%'}}>
      <Typography sx={{ fontFamily: "Oswald", mb: 2, fontSize: '20px' }}>Bethaniëlei 92 2930, Brasschaat België</Typography>
      <Typography sx={{ fontFamily: "Oswald", mb: 2, fontSize: '20px' }}>trimsalonminimax@icloud.com</Typography>
      <Typography sx={{ fontFamily: "Oswald", mb: 2, fontSize: '20px' }}>(+32) 471 42 06 13</Typography>
      <iframe
        title="maps location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4987.403565154472!2d4.50656237717533!3d51.316610671770704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c4064767227795%3A0x362e0401caed8932!2sBethani%C3%ABlei%2092%2C%202930%20Brasschaat!5e0!3m2!1snl!2sbe!4v1713074873970!5m2!1snl!2sbe"
        width={!matches ? '100%' : '400'}
        height={!matches ? '100%' : '300'}
        style={{ border: "0px" }}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
}
