import {TextField, Button, Typography, useMediaQuery} from "@mui/material";
import React, {useState} from "react";
import emailjs from "@emailjs/browser";
import MessageSent from '../../../../assets/messageSent.jpeg'
import {Box} from "@mui/system";

export default function ContactForm() {
    const matches = useMediaQuery("(min-width:1000px)");
    const [nameError, setNameError] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [naam, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [sent, setSend] = useState<boolean>(false);

    function sendMessage(e: any) {
        if (naam.length < 1) {
            setNameError(true);
        } else if (email.length < 1) {
            setEmailError(true);
        } else {
            e.preventDefault();
            emailjs
                .send("service_ezhsz1k", "template_fl0pque", {
                    user_email: email,
                    message: message,
                    user_name: naam,
                }, "l98h2taRVpn3cdtJO")
                .then(
                    (result) => {
                        console.log("message send");
                        setSend(true);
                    },
                    (error) => {
                        console.log("message could not be send");
                    }
                );
        }
    }

    function handleName(event: {
        target: { value: React.SetStateAction<string> };
    }) {
        setName(event.target.value);
    }

    function handleEmail(event: {
        target: { value: React.SetStateAction<string> };
    }) {
        setEmail(event.target.value);
    }

    function handleMessage(event: {
        target: { value: React.SetStateAction<string> };
    }) {
        setMessage(event.target.value);
    }

    if (sent) {
        return (
            <Box
                sx={{display: "flex", flexDirection: "column", alignItems: 'center'}}
            >
                <Typography variant="h3">Bericht verzonden!</Typography>
                <img height={"80%"} width='80%' src={MessageSent} alt="verzonden"/>
            </Box>
        );
    } else {
        return (
            <form style={{display: "flex", flexDirection: "column"} && !matches ? {width: '100%'} : {width: '50%'}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <TextField
                        sx={{width: "100%", mb: 1}}
                        type="text"
                        name="user_name"
                        label="Naam"
                        variant="outlined"
                        helperText={nameError ? "Je moet een naam ingeven" : ""}
                        onChange={(event) => {
                            handleName(event);
                        }}
                    />
                    <br/>
                    <TextField
                        sx={{width: "100%", mb: 1}}
                        name="user_email"
                        type="email"
                        label="Email"
                        variant="outlined"
                        helperText={emailError ? "Je moet een geldige email ingeven" : ""}
                        onChange={(event) => {
                            handleEmail(event);
                        }}
                    />
                    <br/>
                    <TextField
                        sx={{width: "100%", mb: 1}}
                        onChange={(event) => {
                            handleMessage(event);
                        }}
                        name="message"
                        type="text"
                        label="Bericht"
                        variant="outlined"
                        multiline
                        rows={10}
                    />
                    <br/>
                    <Button
                        sx={{ml: 3, mr: 3}}
                        variant="outlined"
                        color="secondary"
                        onClick={sendMessage}
                    >
                        verzenden
                    </Button>
                </Box>
            </form>
        );
    }
}
