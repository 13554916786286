import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { SocialIcon } from "react-social-icons";

export default function Footer() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'center', mt: 10, backgroundColor: "#494949", p: 2 }}>
      <Box sx={{ display: "flex", width: '100%', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Typography variant="h6" color={"#a98c6f"}>Contacteer ons</Typography>
          <Typography color={"#fff9f1"}>trimsalonminimax@icloud.com</Typography>
          <Typography color={"#fff9f1"}>(+32) 471 42 06 13</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Typography variant="h6" color={"#a98c6f"}>Locatie</Typography>
          <Typography color={"#fff9f1"}>Bethaniëlei 92</Typography>
          <Typography color={"#fff9f1"}>2930, Brasschaat België</Typography>
        </Box>
      </Box>
      <Divider color="#b88f51" sx={{mr: 20, ml: 20, mt: 2, mb: 2}} flexItem={true} variant="middle" />
      <Box sx={{ display: "flex" }}>
        <SocialIcon style={{margin: "5px"}} url="https://www.instagram.com/trimsalonminimax/"/>
        <SocialIcon style={{margin: "5px"}} url="https://www.facebook.com/trimsalonminimax" />
      </Box>
    </Box>
  );
}
