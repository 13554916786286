import { Box, Typography } from "@mui/material";
import React from "react";
import Infocard from "../../../../components/infocard-component/Infocard";
import AdvicePng from "../../../../assets/whatwedoimages/Advies.png"
import FullGroomingPng from "../../../../assets/whatwedoimages/FullGrooming.png"
import PuppyGewenning from "../../../../assets/whatwedoimages/PuppyGewenning.png"

export default function WhatDoWeDo() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        color="#b88f51"
        variant="h2"
        sx={{ fontFamily: "Ms Madi", m: 3, alignSelf: "center" }}
      >
        Wat doen we?
      </Typography>
      <Box
        sx={{width: "1OO%", display: 'flex', justifyContent: 'space-evenly', flexWrap: "wrap"}}
      >
        <Infocard backgroundColor={'#a98c6f'} textColor={'#fff'} title={"Advies"} description={"Ik vertel je graag welke technieken en producten je kan gebruiken om ook thuis je trouwe vriend te kunnen verzorgen; deze professionele producten kan je, indien gewenst, bij mij in het salon aanschaffen."} image={AdvicePng} />
        <Infocard backgroundColor={'#f7f7f2'} textColor={'#000'} title={"Fullgrooming"} description={"Alles om jouw lieve viervoeter weer tip-top te laten stralen : knippen, wassen, scheren, oortjes en oogjes reinigen, nagels knippen,…"} image={FullGroomingPng} />
        <Infocard backgroundColor={'#9aa5ab'} textColor={'#fff'} title={"Puppygewenning"} description={"          Een nieuwe pup in huis ? Laat hem kennis maken met het bad, trimtafel, droger, trimgereedschap en natuurlijk de trimster. Dit is gratis tot de leeftijd van 6 maanden op vertoon van het paspoort."} image={PuppyGewenning} />
      </Box>
    </Box>
  );
}
