import { Typography } from '@mui/material'
import { Container } from '@mui/system'
import React from 'react'
import TipComponent from '../../components/tip-component/TipComponent'

export default function Tips() {
  return (
    <Container sx={{display: 'flex', flexDirection: 'column'}} component={'div'}>
        <Typography variant='h2' sx={{fontFamily: "Ms Madi", color: "#b88f51", alignSelf: 'center'}}>Tips & Info</Typography>
        <TipComponent description={'Laat uw hond op voorhand even uit om zijn behoefte te laten doen; een trimbeurt neemt namelijk enige tijd in beslag.'} />
        <TipComponent description={'Gelieve me op de hoogte te brengen van gekende vacht-of gedragsproblemen zodat hier rekening mee gehouden kan worden en incidenten kunnen vermeden worden.'} />
        <TipComponent description={'Borstel uw hond regelmatig zodat de trimbeurt niet langer duurt dan nodig en er geen extra kosten dienen aangerekend te worden.'} />
        <TipComponent description={'Annulaties dienen 48 uur op voorhand te gebeuren, anders ben ik genoodzaakt om de trimbeurt gedeeltelijk aan te rekenen.'} />
        <TipComponent description={'U kan in het salon steeds betalen met cash (gepast), payconiq of de bankapp.'} />
        <TipComponent description={'Het is aangewezen om tijdens de trimbeurt niet in het salon te blijven, ik heb een beperkte ruimte en de hond zal zich ook anders gedragen met zijn baasje erbij.'} />
    </Container>
  )
}
