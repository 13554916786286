import {
  Toolbar,
  AppBar,
  Box,
  Link,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import MiniMaxLogo from "../../assets/MiniMaxLogo.png";
import BackgroundBanner from "../../assets/backgrounds/header.png";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";

const buttonSX = {
  fontWeight: "bold",
  fontSize: "20px",
  m: 2,
  textDecoration: "none",
  "&:hover": {
    fontSize: "25px",
    color: "#b88f51",
  },
};

function Header() {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const clicked = {
    fontSize: "25px",
    textDecoration: "underline",
    fontWeight: "bold",
    textDecorationColor: "#b88f51",
  };

  const drawerWidth = 240;

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const matches = useMediaQuery("(min-width:1000px)");

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", backgroundColor: "#fff9f1", height: "100%" }}
    >
      <Box sx={{display: "flex", alignItems: 'center', justifyContent:'center', width: "100%"}}>
        <img height={"70px"} src={MiniMaxLogo} alt={"Logo"} />
        <Typography variant="h6" sx={{ my: 2 }}>
          MiniMax
        </Typography>
      </Box>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              navigate("/");
              setMobileOpen(false);
            }}
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary={"Over Minimax"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              navigate("/me");
              setMobileOpen(false);
            }}
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary={"Over Mij"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              navigate("/prijslijst");
              setMobileOpen(false);
            }}
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary={"Prijslijst"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              navigate("/tips");
              setMobileOpen(false);
            }}
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary={"Nuttige tips"} />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton
            onClick={() => {
              navigate("/contact");
              setMobileOpen(false);
            }}
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary={"Contact"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        sx={{
          alignItems: "center",
          background: `url(${BackgroundBanner})`,
          height: "75px",
        }}
      >
        <Toolbar sx={{ width: "80%", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img height={"70px"} src={MiniMaxLogo} alt={"Logo"} />
          </Box>

          {!matches && (
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ color: "black" }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}
          {matches && (
            <Box>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigate("/");
                }}
                color={"primary"}
                sx={path === "/" ? clicked : buttonSX}
              >
                Over MiniMax
              </Link>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigate("/me");
                }}
                color={"primary"}
                sx={path === "/me" ? clicked : buttonSX}
              >
                Over mij
              </Link>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigate("/prijslijst");
                }}
                color={"primary"}
                sx={path === "/prijslijst" ? clicked : buttonSX}
              >
                Prijslijst
              </Link>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigate("/tips");
                }}
                color={"primary"}
                sx={path === "/tips" ? clicked : buttonSX}
              >
                Tips & Info
              </Link>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigate("/contact");
                }}
                color={"primary"}
                sx={path === "/contact" ? clicked : buttonSX}
              >
                Contact
              </Link>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}

export default Header;
