import { Box, Divider, Typography } from "@mui/material";
import { Container } from "@mui/system";
import AboutMePic from "../../assets/aboutMe.png";
import React from "react";

export default function AboutMe() {
  return (
    <Container maxWidth='lg' component={'div'}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          color="#b88f51"
          sx={{ fontFamily: "Ms Madi", alignSelf: "center" }}
          variant="h2"
        >
          Wie ben ik?
        </Typography>
        <img src={AboutMePic} alt="Ik met de honden" />
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
          <Typography
            sx={{ fontFamily: "Open Sans", alignSelf: "center", fontSize: "20px", mt: 5 }}
          >
            Hallo, ik ben Sofie, sinds 2022 baat ik mijn kleinschalig hondensalon voor grote en kleine honden uit.
            Al sinds kleins af aan heb ik een enorm groot hart voor dieren, daarom is het altijd een droom geweest om van dieren verzorgen mijn beroep te maken.
          </Typography>
          <Divider color="#b88f51" sx={{mr: 20, ml: 20, mt: 5, mb: 5}} flexItem={true} variant="middle" />
          <Typography
            sx={{ fontFamily: "Open Sans", alignSelf: "center", fontSize: "20px" }}
          >
            Na het afronden van een opleiding als zorgkundige en animator in de
            bejaardenzorg heb ik beslist om, naast het verzorgen van ouderen mij
            ook toe te leggen op het verzorgen van honden en hiermee mijn droom
            na te jagen. Hiervoor heb ik een voltijdse opleiding gevolgd bij
            Syntra te Berchem waar ik mijn diploma tot hondentrimmer en
            pensionuitbater heb behaald. Gedurende deze periode heb ik zowel in
            het school als op stage heel wat ervaring opgedaan.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}
