import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import Header from "./components/header/Header";
import { createTheme } from "@mui/material";
import LandingPage from "./pages/landing-page/LandingPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutMe from "./pages/about-me/AboutMe";
import Footer from "./components/footer/Footer";
import PriceList from "./pages/pricelist/PriceList";
import Contact from "./pages/contact/Contact";
import Tips from "./pages/tips-info/Tips";

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }
  interface PaletteOptions {
    white: PaletteOptions["primary"];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
}

export const theme = createTheme({
  palette: {
    background: {
      default: "#fff9f1",
    },
    primary: {
      main: "#1e1e1e",
      contrastText: "#57c7ff",
    },
    secondary: {
      main: "#b88f51",
      light: "#34b1ff",
      contrastText: "#fcf4d9",
    },
    white: {
      main: "#fff",
      contrastText: "#525464",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: () => ({
          "&.MuiButton-outlinedSecondary:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main
          },
        }),
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ marginTop: "75px" }}>
        <BrowserRouter>
          <Header />
          <CssBaseline />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/me" element={<AboutMe />} />
            <Route path="/prijslijst" element={<PriceList />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/tips" element={<Tips />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
