import { Paper, Typography, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import ContactForm from "./components/contact/ContactForm";
import ContactInfo from "./components/contact/ContactInfo";

export default function Contact() {
  const matches = useMediaQuery("(min-width:1000px)");

  return (
    <Container sx={{ display: "flex", flexDirection: "column" }} component={'div'}>
      <Typography
        variant="h2"
        sx={{ fontFamily: "Ms Madi", alignSelf: "center" }}
        color="#b88f51"
      >
        Contact
      </Typography>
      {matches && (
        <Paper
          sx={{
            display: "flex",
            p: 5,
            m: 2,
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <ContactForm />
          <ContactInfo />
        </Paper>
      )}
      {!matches && (
        <Paper
          sx={{
            display: "flex",
            p: 5,
            m: 2,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <ContactForm />
          <ContactInfo />
        </Paper>
      )}
    </Container>
  );
}
