import { Box } from '@mui/material'
import React from 'react'
import Salon1 from "../../../../assets/salon/salon1.png"
import Salon2 from "../../../../assets/salon/salon2.png"
import Salon3 from "../../../../assets/salon/salon3.png"
import Carousel from 'react-material-ui-carousel'

export default function SalonCaroussel() {
    var pictures = [Salon1, Salon2, Salon3]
  return (
    <Box sx={{height: "100%", width: "100%", mt: 10}}>
        <Carousel sx={{height: "100%", width: "100%"}}>
            {
                pictures.map((picture) =>  <PictureItem pic={picture} />) 
            }
        </Carousel>
    </Box>
  )
}

interface Props {
    pic: string
}

function PictureItem({pic}: Props) {
    return (
        <img height={"100%"} width={"100%"} src={pic} alt="Salon"/>
    )
}
