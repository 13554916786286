import {Card, CardActionArea, CardContent, CardMedia, Typography} from "@mui/material";
import React from "react";

interface InfoCardProps {
    backgroundColor: string,
    textColor: string
    title: string,
    description: string,
    image: any
}

export default function Infocard({backgroundColor, textColor, title, description, image}: InfoCardProps) {
    return (
        <Card sx={{ maxWidth: 345, height: 350, m: 2 }}>
            <CardActionArea>
                <CardContent sx={{height: 200, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: {backgroundColor}}}>
                    <Typography gutterBottom variant="h5" color={textColor}>
                        {title}
                    </Typography>
                    <Typography sx={{fontSize: "15px", fontFamily: "Open Sans"}} color={textColor}>
                        {description}
                    </Typography>
                </CardContent>
                <CardMedia
                    component="img"
                    height="150"
                    image={image}
                    alt={title}
                />
            </CardActionArea>
        </Card>
    );
}