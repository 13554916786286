import { Box, Typography } from '@mui/material'
import React from 'react'
import LabelIcon from '@mui/icons-material/Label';

interface TipProps {
    description: string
}

export default function TipComponent({description}: TipProps) {
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <LabelIcon />
      <Typography sx={{ fontFamily: "Open Sans", fontSize: "20px", m: 2 }}>{description}</Typography>
    </Box>
  )
}
