import {Box, Divider, Typography, useMediaQuery} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";

export default function AboutMiniMax() {
  const minWidth = useMediaQuery("(min-width:1000px)");

  return (
    <Box>
      <Container component={'div'}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          } && (minWidth ? {p: 10} : {p: 0})}
        >
          <Typography
            color="#b88f51"
            sx={{ fontFamily: "Ms Madi" }}
            variant="h2"
          >
            Over MiniMax
          </Typography>
          <Typography
            color="#333333"
            sx={{ fontFamily: "Open Sans", mt: 5, mb: 5, fontSize: "20px" }}
          >
            Bij MiniMax vind je alles om je trouwe viervoeter te verzorgen en terug te laten stralen.
            Je kan bij mij terecht voor een complete trimbeurt, maar ook om enkel te wassen, nageltjes te knippen, enz...
          </Typography>
          <Typography
              color="#333333"
              sx={{ fontFamily: "Open Sans", mt: 5, mb: 5, fontSize: "20px" }}
          >
            Momenteel werk ik op dinsdag en donderdag namiddag in het salon om de combinatie met mijn andere job in de thuiszorg te kunnen combineren.
            Sinds kort ben ik ook met mijn trimsalon verhuisd naar de Bethaniëlei in Brasschaat waar ik met veel plezier uw viervoeter wil ontvangen.
          </Typography>
          <Divider color="#b88f51" sx={{}} flexItem={true} variant="middle" />
          <Typography
            color="#333333"
            sx={{ fontFamily: "Open Sans", mt: 5, fontSize: "20px" }}
          >
            Voor de allerkleinsten tot de leeftijd van 6 maanden bied ik een
            gratis puppygewenning aan om de pup te laten wennen aan alle
            indrukken/geuren/geluiden van het trimsalon om zo de stress voor een
            toekomstige trimbeurt tot een minimum te beperken.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
