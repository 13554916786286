import { Box } from "@mui/material";
import Welcome from "../../assets/backgrounds/welcomeBanner.jpg"
import React from "react";
import About from "./components/about-minimax/AboutMiniMax";
import WhatDoWeDo from "./components/extra-info/WhatDoWeDo";
import Salon from "./components/about-salon/Salon";

export default function LandingPage() {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <img width={"100%"} src={Welcome} alt="banner"/>
      <About />
      <Salon />
      <WhatDoWeDo />
    </Box>
  );
}
