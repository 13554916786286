import {Button, Divider, Typography, useMediaQuery} from "@mui/material";
import {Box, Container} from "@mui/system";
import React from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function PriceList() {
    const minWidth = useMediaQuery("(min-width:1400px)");

    const onDownload = () => {
        const link = document.createElement("a");
        link.download = `prijslijst.pdf`;
        link.href = "prijslijst.pdf";
        link.click();
    };

    return (
        <Container component="div">
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography
                    color="#b88f51"
                    sx={{fontFamily: "Ms Madi"}}
                    variant="h2"
                >
                    Prijs info
                </Typography>
                <Typography sx={{fontFamily: "Open Sans", fontSize: "20px", m: 2}}>
                    De hond kan getrimd worden volgens de rasstandaard of volgens de
                    wens van de klant. Voor de prijs verwijs ik je graag door naar
                    onderstaande lijst.
                </Typography>
                <Typography sx={{fontFamily: "Open Sans", fontSize: "20px", m: 2}}>
                    In de basisprijs is inbegrepen : wassen,
                    drogen, borstelen, ontwollen, knippen/scheren (naargelang
                    het vachttype), nagels knippen, voetzooltjes uitscheren/knippen,
                    oren en ogen reinigen. Omdat de conditie van de vacht en het gedrag
                    van de hond bepalend zijn voor de duur van de trimbeurt, zijn dit
                    “vanaf” prijzen en kunnen er supplementen aangerekend worden ingeval
                    van ongedierte, overmatige klitvorming, agressief, onhandelbaar
                    gedrag,…
                </Typography>
                <Typography sx={{fontFamily: "Open Sans", fontSize: "20px", m: 2}}>
                    Deze extra’s worden gerekend aan een meerprijs van 40€ per uur. U en
                    uw hond hebben er daarom alle belang bij om de hond tijdig te laten
                    trimmen en hem thuis ook regelmatig te kammen, ontvlooien,…
                </Typography>
            </Box>
            <Divider color="#b88f51" sx={{}} flexItem={true} variant="middle"/>
            <Box>
                <Typography sx={{fontFamily: "Roboto", fontSize: "20px", m: 2}}>
                    Voor een kleine verzorging of extra behandeling gelden de volgende
                    prijzen :
                </Typography>
                <Typography sx={{fontFamily: "Roboto", fontSize: "20px", m: 2}}>
                    – nagels knippen : € 10,00
                </Typography>
                <Typography sx={{fontFamily: "Roboto", fontSize: "20px", m: 2}}>
                    – ontklitten : € 40,00 per uur
                </Typography>
                <Typography sx={{fontFamily: "Roboto", fontSize: "20px", m: 2}}>
                    – vlooienbehandeling : € 15,00 extra (voor speciale shampoo en extra
                    reiniging van het salon)
                </Typography>
                <Typography sx={{fontFamily: "Roboto", fontSize: "20px", m: 2}}>
                    – puppygewenning : gratis tot 6 maanden.
                </Typography>
            </Box>
            {minWidth && <embed
                src="https://trimsalonminimax.s3.eu-west-2.amazonaws.com/assets/prijslijst.pdf"
                width={"100%"}
                height={"650px"}
            />}
            {!minWidth && <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                <Typography sx={{fontFamily: "Open Sans", fontWeight: "bold", fontSize: "20px", m: 2}}>Download de
                    prijslijst hier: </Typography>
                <Button startIcon={<CloudDownloadIcon />} onClick={onDownload} sx={{ml: 3, mr: 3}}
                        variant="contained"
                        color="secondary">Download prijslijst</Button>
            </Box>}
        </Container>
    );
}
